.tooltiptext {
    margin-top: 10px;
    border: 2px solid #000;
    padding: 10px 4px 4px 4px;
    border-radius: 8px;
    background: #fff !important;
    text-align: center;
    position: relative;
    user-select: none;
}

.arrow::after {
    content: "";
    position: absolute;
    top: 70%;
    left: -3.5%;
    width: 7px;
    height: 7px;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #000 transparent transparent transparent;
    transform: rotate(88deg);
}

.close {
    position: absolute;
    right: 5px;
    top: 2px;
    display: flex;
    justify-content: end;
    text-align: right;
    width: 100%;
    height: 10px;
    cursor: pointer;
}

.preview {
    border: 1px solid #000;
    border-radius: 8px;
    padding: 2px 4px;
    margin: 5px 2px;
    cursor: pointer;
}
