body {
    margin: 0;
    background-color: "#FFF8F3 !important";
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@font-face {
    font-family: "GT Flexa";
    src: local("GT Flexa"),
    url("./assets/fonts/GT-Flexa-Standard-Regular.otf") format("embedded-opentype"),
    url("./assets/fonts/GT-Flexa-Standard-Regular.woff2") format("woff2"),
    url("./assets/fonts/GT-Flexa-Standard-Regular.woff") format("woff"),
    url("./assets/fonts/GT-Flexa-Standard-Regular.ttf");
}

@font-face {
    font-family: "GT Flexa";
    src: local("GT Flexa"),
    url("./assets/fonts/GT-Flexa-Standard-Light.otf") format("embedded-opentype"),
    url("./assets/fonts/GT-Flexa-Standard-Light.woff2") format("woff2"),
    url("./assets/fonts/GT-Flexa-Standard-Light.woff") format("woff"),
    url("./assets/fonts/GT-Flexa-Standard-Light.ttf") format("truetype");
    font-weight: 300;
}

@font-face {
    font-family: "GT Flexa";
    src: local("GT Flexa"),
    url("./assets/fonts/GT-Flexa-Standard-Medium.otf") format("embedded-opentype"),
    url("./assets/fonts/GT-Flexa-Standard-Medium.woff2") format("woff2"),
    url("./assets/fonts/GT-Flexa-Standard-Medium.woff") format("woff"),
    url("./assets/fonts/GT-Flexa-Standard-Medium.ttf") format("truetype");
    font-weight: 500;
}

@font-face {
    font-family: "GT Flexa";
    src: local("GT Flexa"),
    url("./assets/fonts/GT-Flexa-Standard-Thin.otf") format("embedded-opentype"),
    url("./assets/fonts/GT-Flexa-Standard-Thin.woff2") format("woff2"),
    url("./assets/fonts/GT-Flexa-Standard-Thin.woff") format("woff"),
    url("./assets/fonts/GT-Flexa-Standard-Thin.ttf") format("truetype");
    font-weight: 100;
}

@font-face {
    font-family: "GT Flexa";
    src: local("GT Flexa"),
    url("./assets/fonts/GT-Flexa-Standard-Bold.otf") format("embedded-opentype"),
    url("./assets/fonts/GT-Flexa-Standard-Bold.woff2") format("woff2"),
    url("./assets/fonts/GT-Flexa-Standard-Bold.woff") format("woff"),
    url("./assets/fonts/GT-Flexa-Standard-Bold.ttf") format("truetype");
    font-weight: 700;
}

@font-face {
    font-family: "GT Flexa";
    src: local("GT Flexa"),
    url("./assets/fonts/GT-Flexa-Standard-Black.otf") format("embedded-opentype"),
    url("./assets/fonts/GT-Flexa-Standard-Black.woff2") format("woff2"),
    url("./assets/fonts/GT-Flexa-Standard-Black.woff") format("woff"),
    url("./assets/fonts/GT-Flexa-Standard-Black.ttf") format("truetype");
    font-weight: 900;
}

@font-face {
    font-family: "GT Flexa Mono";
    src: local("GT Flexa"),
    url("./assets/fonts/GT-Flexa-Mono-Regular.otf") format("embedded-opentype"),
    url("./assets/fonts/GT-Flexa-Mono-Regular.woff2") format("woff2"),
    url("./assets/fonts/GT-Flexa-Mono-Regular.woff") format("woff"),
    url("./assets/fonts/GT-Flexa-Mono-Regular.ttf");
}

@font-face {
    font-family: "GT Flexa Mono";
    src: local("GT Flexa"),
    url("./assets/fonts/GT-Flexa-Mono-Light.otf") format("embedded-opentype"),
    url("./assets/fonts/GT-Flexa-Mono-Light.woff2") format("woff2"),
    url("./assets/fonts/GT-Flexa-Mono-Light.woff") format("woff"),
    url("./assets/fonts/GT-Flexa-Mono-Light.ttf") format("truetype");
    font-weight: 300;
}

@font-face {
    font-family: "GT Flexa Mono";
    src: local("GT Flexa"),
    url("./assets/fonts/GT-Flexa-Mono-Medium.otf") format("embedded-opentype"),
    url("./assets/fonts/GT-Flexa-Mono-Medium.woff2") format("woff2"),
    url("./assets/fonts/GT-Flexa-Mono-Medium.woff") format("woff"),
    url("./assets/fonts/GT-Flexa-Mono-Medium.ttf") format("truetype");
    font-weight: 500;
}

@font-face {
    font-family: "GT Flexa Mono";
    src: local("GT Flexa"),
    url("./assets/fonts/GT-Flexa-Mono-Thin.otf") format("embedded-opentype"),
    url("./assets/fonts/GT-Flexa-Mono-Thin.woff2") format("woff2"),
    url("./assets/fonts/GT-Flexa-Mono-Thin.woff") format("woff"),
    url("./assets/fonts/GT-Flexa-Mono-Thin.ttf") format("truetype");
    font-weight: 100;
}

@font-face {
    font-family: "GT Flexa Mono";
    src: local("GT Flexa"),
    url("./assets/fonts/GT-Flexa-Mono-Bold.otf") format("embedded-opentype"),
    url("./assets/fonts/GT-Flexa-Mono-Bold.woff2") format("woff2"),
    url("./assets/fonts/GT-Flexa-Mono-Bold.woff") format("woff"),
    url("./assets/fonts/GT-Flexa-Mono-Bold.ttf") format("truetype");
    font-weight: 700;
}

@font-face {
    font-family: "GT Flexa Mono";
    src: local("GT Flexa"),
    url("./assets/fonts/GT-Flexa-Mono-Black.otf") format("embedded-opentype"),
    url("./assets/fonts/GT-Flexa-Mono-Black.woff2") format("woff2"),
    url("./assets/fonts/GT-Flexa-Mono-Black.woff") format("woff"),
    url("./assets/fonts/GT-Flexa-Mono-Black.ttf") format("truetype");
    font-weight: 900;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
